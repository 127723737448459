import React, {ReactNode, useCallback, useMemo, useState} from "react";
import Chats from "../chats/Chats";
import {useLocation, useNavigate} from 'react-router-dom';
import {ChatContainerStyles, ChatContainerStylesUndecorated} from "./ChatbotStyles";
import {Alert, Avatar, Box, Button, Chip, Grid, IconButton, Link, Tooltip, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import {ChatResponse, PageContent, VideoSearchMatch} from "../../../model/ChatResponse";
import i18n from "i18next";
import {ChatbotSettings, Embeddings, FeedbackContent} from "../../../model/Chatbot";
import {useChatbotApiClient} from "../../../clients/ChatbotApiClient";
import {IoCloseSharp, IoSettingsOutline} from "react-icons/io5";
import ChatSettingsModal from "./ChatSettingsModal";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import {useHelperBotContext} from "../../../shared/contexts/HelperBotContext";
import {useTranslation} from "react-i18next";
import {cleanLinks} from "../../../shared/tools/StringTools";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import LanguageSwitcher from "../../../shared/components/LanguageSwitcher";
import {IoIosInformationCircleOutline, IoIosSend} from "react-icons/io";
import {useUserContext} from "../../../shared/contexts/UserContext";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import {PiBird, PiButterfly, PiChartLineUpLight, PiCrown, PiGraduationCapLight, PiMoonStars} from "react-icons/pi";
import {TbMessageChatbot} from "react-icons/tb";
import {BsEmojiLaughing} from "react-icons/bs";
import {GiTiedScroll} from "react-icons/gi";
import {HiOutlineHandThumbUp} from "react-icons/hi2";
import {HiOutlineKey} from "react-icons/hi";
import HtmlRenderer from "../../../shared/components/HTMLRenderer";
import {RiChatDownloadLine, RiChatUploadLine} from "react-icons/ri";
import PulsatingButton from "../../../shared/components/PulsatingButton";
import SpeechBubble from "../../../shared/components/SpeechBubble";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import Dictaphone2 from "../../../shared/components/Dictaphone2";
import InteractiveAvatar from "../../chatbot/components/InteractiveAvatar2";
import {AvatarQuality} from "@heygen/streaming-avatar";
import UploadFileModal2 from "../../chatbot/components/UploadFileModal2";
import FileIconsList from "../../chatbot/components/FileIconsList";
import { MdOutlineAttachFile } from "react-icons/md";
import { CiFilter } from "react-icons/ci";
import HierarchyTree from "../../chatbot/components/HierarchyTree";
import UploadFileModal from "../../chatbotSettings/components/UploadFileModal";
import LoginModal from "./LoginModal";

interface ChatProps {
    nlpTask: string
    nlpModel?: string | null 
    botName: string
    botDescription: string
    botId: string
    undecorated?: boolean
    questionToAsk?: string
    botTopics?: string[]
    botSettings: ChatbotSettings
    postVideoToIframe?: (videoData: {title: string, url: string, jwt: string, searchMatches: VideoSearchMatch[], thumbnail: string})=>void
    userGaveConsent?: boolean
    onClose?: () => void
    sendEventFromIFrameToParent?: (eventName: string, eventPayload: string) => void
}


export interface ChatMessage {
    purpose: string;
    message: string;
    options?: string[];
    sender: string;
    timestamp: string;
    sources: Array<PageContent>;
    best_sources: string;
    cost: number;
    query_about: string;
}

interface AvatarOption {
    icon: ReactNode;
    name: string;
    descr: string;
    prompt: string;
}

const avatarOptions = [
    {icon: <IoIosSend />, name: 'Standard', descr: 'Formell und höflich', prompt: 'Verhalte dich formell, höflich und empatisch. Nutze eine respektvolle Ansprache und höfliche Formulierungen.'},
    {icon: <PiButterfly />, name: 'Elton John', descr: 'Umgangssprachlich und locker', prompt: 'Verhalte dich wie Elton John, umgangssprachlich und locker. Sprich die Nutzer in einem entspannten, freundlichen Ton an.'},
    {icon: <BsEmojiLaughing />, name: 'Robin Williams', descr: 'Humorvoll und witzig', prompt: 'Verhalte dich wie Robin Williams, humorvoll und witzig. Nutze humorvolle, unterhaltsame Formulierungen, um den Nutzern ein Lächeln zu schenken.'},
    {icon: <PiBird />, name: 'Ulmer Schwabe', descr: 'Schwäbisch und Bodenständig', prompt: 'Sprich im Schwäbischen Dialekt. Nutze typische Ausdrücke und eine freundliche, bodenständige Art.'},
    {icon: <PiChartLineUpLight />, name: 'Billie Eilish', descr: 'Jugendlich und trendy', prompt: 'Verhalte dich wie Billie Eilish, jugendlich und trendy. Nutze moderne, coole Ausdrücke und eine lockere Ansprache.'},
    {icon: <PiCrown />, name: 'Johann Wolfgang von Goethe', descr: 'Historisch und altmodisch', prompt: 'Verhalte dich wie Johann Wolfgang von Goethe, historisch und altmodisch. Nutze gehobene Sprache und förmliche Ansprache.'},
    {icon: <GiTiedScroll />, name: 'William Shakespeare', descr: 'Lyrisch und poetisch', prompt: 'Verhalte dich wie William Shakespeare, lyrisch und poetisch. Nutze poetische Formulierungen und eine kreative Ansprache.'},
    {icon: <HiOutlineHandThumbUp />, name: 'Oprah Winfrey', descr: 'Motivierend und inspirierend', prompt: 'Verhalte dich wie Oprah Winfrey, motivierend und inspirierend. Nutze aufmunternde und positive Formulierungen, um die Nutzer zu ermutigen.'},
    {icon: <PiMoonStars />, name: 'Nostradamus', descr: 'Mystisch und geheimnisvoll', prompt: 'Verhalte dich wie Nostradamus, mystisch und geheimnisvoll. Nutze geheimnisvolle und rätselhafte Formulierungen, um eine mystische Atmosphäre zu schaffen.'},
    {icon: <HiOutlineKey />, name: 'Yoda', descr: 'Weise und kryptisch', prompt: 'Verhalte dich wie Yoda aus Star Wars, weise und kryptisch. Nutze eine umgekehrte Satzstruktur und weise, rätselhafte Formulierungen.'},
];

const getAnswerLanguage = (lang: string): string => {
    if (lang === "de") {
        return "Deutsch"
    } else if (lang === "en") {
        return "English"
    } else if (lang === "tr") {
        return "Türkçe"
    } else if (lang === "ua") {
        return "українська"
    } else if (lang === "ar") {
        return "العربية"
    } else if (lang === "hr") { // Kroatisch
        return "Hrvatski"
    } else if (lang === "ro") { // Rumänisch 
        return "Română"
    } else if (lang === "sq") { // Albanisch
        return "Shqip"
    } else if (lang === "sr") { // Serbisch
        return "Српски"
    } else if (lang === "hu") { // Ungarisch
        return "Magyar"
    } else if (lang === "pl") { // Polnisch
        return "Polski"
    } else if (lang === "ru") { // Russisch
        return "Русский"
    } else if (lang === "mk") { // Mazedonisch
        return "Македонски"
    } else if (lang === "es") { // Spanisch
        return "Español"
    } else if (lang === "fr") { // Französisch
        return "Français"
    } else if (lang === "it") { // Italienisch
        return "Italiano"
    } else if (lang === "ja") { // Japanisch
        return "日本語"
    } else if (lang === "el") { // Griechisch 
        return "Ελληνικά"
    } else {
        return "English"
    }
}

const emptyMessage = {
    purpose: "introduction",
    message: "...",
    sender: "bot",
    timestamp: new Date().toLocaleString(),
    sources: new Array<PageContent>(),
    best_sources: "",
    cost: 0,
    query_about: "",
}


const Chat: React.FC<ChatProps> = (props: ChatProps) => {

    const chatbotClient = useChatbotApiClient({ preventNotification: true })
    const userCtx = useUserContext()
    const theme = useTheme()
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation()
    // eslint-disable-next-line
    const {questionToAsk, setQuestionToAsk } = useHelperBotContext()
    // const isMobile = useCheckMobileScreen()
    
    const botOpacity = props.botSettings.botOpacity || 1

    const defaultSysPrompt_en = `You are a helpful, respectful and honest assistant. Your name is '{bot.name}'.`
    const defaultSysPrompt_de = `Du bist ein hilfreicher, respektvoller und ehrlicher Assistent. Dein Name ist '{bot.name}'.`
    
    // const promt_en = `Use the following pieces of context to answer the question at the end. {context} Question: {question}`
    // const promt_de = `Verwende die folgenden Kontextinformationen, um die Frage am Ende prägnant zu beantworten. {context} Frage: {question}`
    // const promt_en = `Use the following pieces of context to answer the question at the end. {context} Question: {question} . Answer in english only. Answer in as few sentences as possible.`
    // const promt_de = `Gib deine Antworten ausschließlich in Deutsch. Antworte in so wenigen Sätzen wie möglich. Verwende die folgenden Kontextinformationen, um die Frage am Ende zu beantworten. {context}. Frage: {question} .`

    const preparePrompt = (input: string) => {
        return input.replaceAll("{{bot.name}}", props.botName).replaceAll("{{botName}}", props.botName)
    }
    
    // Chat settings
    const [botTitleText, setBotTitleText] = useState<string>(props.botName)
    const [botIcon, setBotIcon] = useState<string>("/img/bb_solo_small.png")
    const [botColors, setBotColors] = useState<{ [key: string]: string }>({
        primary: '#557A46',
        light: '#F2EE9D',
        timestamp: '#858585',
        bot: '#C7C7C7',
        botText: '#000',
        botbackground: '#ffffff',
        contrast: '#000000', 
    })
    const [loginRequired] = useState<boolean>(props.botSettings?.hasUserAuthBeforeChatEnabled || false)
    const [loggedIn, setLoggedIn] = useState<boolean>(false)
    const [botUsername, setBotUsername] = useState<string>("")
    const [botPassword, setBotPassword] = useState<string>("")
    
    const [settingsModalOpen, setSettingsModalOpen] = useState<boolean>(false)
    const [embeddings, setEmbeddings] = useState<Embeddings>(Embeddings.OPEN_AI_TE_3_LARGE)
    const [chatHistoryFromServer, setChatHistoryFromServer] = useState<string>("[]")
    const [nlpModel, setNlpModel] = useState<string>((props.nlpModel || props.botSettings?.nlpModel || "OpenAI/gpt-4o")) // "OpenAI/gpt-3.5-turbo" "OpenAI/gpt-4o"  "meta/llama-2-70b-chat"
    const [nlpTask, setNlpTask] = useState<string>(props.nlpTask) // "RAG" - "CIS"
    const [maxNewTokens, setMaxNewTokens] = useState<number>(props.botSettings?.maxNewTokens || 1500)
    const [temperature, setTemperature] = useState<number>(props.botSettings?.temperature || 0.01)
    const [fetchSimilarDocs, setFetchSimilarDocs] = useState<number>(props.botSettings?.chunksToFetch || 5)
    const [defaultSysPromt, setDefaultSysPromt] = useState<string>(preparePrompt(props.botSettings?.defaultSystemPromptEn || defaultSysPrompt_en))
    const [textToSay, setTextToSay] = useState<string>("")
    // unused
    const [maxLen, setMaxLen] = useState<number>(1500)
    const [topP, setTopP] = useState<number>(0.9)
    const [similarityScoreThreshold, setSimilarityScoreThreshold] = useState<number>(0.2)
    // const [promt, setPromt] = useState<string>(promt_en)
    
    const [autoReadResults, setAutoReadResults] = useState<boolean>(false)
    const [checkAnswers, setCheckAnswers] = useState<boolean>(props.botSettings?.checkAnswers || false)
    const [addSources, setAddSources] = useState<boolean>(props.botSettings?.addSources || false)
    const [base64_pdf, setBase64_pdf] = useState<string>("")
    const [streamAnswer, setStreamAnswer] = useState<string>("stream: ")
    const [infoScreen, setInfoScreen] = useState<boolean>(false)
    const [filterVisible, setFilterVisible] = useState<boolean>(false)
    const [endRecording, setEndRecording] = useState<boolean>(false)
    const [isQuestionRecording, setIsQuestionRecording] = useState<boolean>(false)

    const [selctedTopic, setSelctedTopic] = useState<string>("")

    const [addFileModalOpen, setAddFileModalOpen] = useState(false)
    const [attachFileModalOpen, setAttachFileModalOpen] = useState(false)
 
    // Text-to-speech:
    const utterThis = useMemo(() => new SpeechSynthesisUtterance(), [])
    // utterThis.lang = "en-US";
    utterThis.lang = i18n.language
    const [easyLangEnabled, setEasyLangEnabled] = React.useState<boolean>(false)

    const [showWaitForResponse, setShowWaitForResponse] = useState<boolean>(false);
    const [userResponse, setUserResponse] = useState<string>("");
    const [questionFromOutside, setQuestionFromOutside] = useState<string>("");
    const [messages, setMessages] = useState<ChatMessage[]>([emptyMessage])
    const [pageError, setPageError] = useState<string>()

    // avatar select ("personality")
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedAvatar, setSelectedAvatar] = useState<AvatarOption>({ icon: botIcon, name: '', descr: '', prompt: '' });

    // "hallucination" warning bubble
    const [showBubble, setShowBubble] = useState(true);
    // Feedback-message
    const [awaitUserFeedback, setAwaitUserFeedback] = useState(false);

    const [userGaveConsent, setUserGaveConsent] = useState<boolean>(false); // Initially, user must agree to process data
    
    // this file is send along with the chat request to serve as extra-knowledge
    const [selectedAdditionalFileToChatWith, setSelectedAdditionalFileToChatWith] = useState<File | null>(null);

    const handleDeleteConsent = () => {
        setUserGaveConsent(true);
    };

    const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleAvatarClose = () => {
        setAnchorEl(null);
    };

    const handleAvatarMenuItemClick = (option: AvatarOption) => {
        if (option.name === "Standard") {
            setTemperature(props.botSettings?.temperature || 0.01)
            setSelectedAvatar({ icon: botIcon, name: '', descr: '', prompt: '' })
        } else {
            setTemperature(0.5)
            setSelectedAvatar(option);
        }
        handleAvatarClose();
    };

    const handleCloseBubble = () => {
        setShowBubble(false);
    };

    React.useEffect(() => {
        setMessages(getWelcomeMessages(i18n.language))
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        if (props.userGaveConsent) {
            setUserGaveConsent(props.userGaveConsent)
        }
        // eslint-disable-next-line
    }, [props.userGaveConsent]);
    
    const speechHandler = useCallback((msg: string, autoReadResults: boolean) => {
        console.log(autoReadResults)
        console.log(msg)
        if (!autoReadResults) {
            console.log("Dont auto-read results")
            return
        } else {
            utterThis.text = msg
            window.speechSynthesis.speak(utterThis)    
        }
    }, [utterThis])
    
    React.useEffect(() => {
        console.log("Lang is: " + i18n.language)
        setMessages(getWelcomeMessages(i18n.language))
        if (i18n.language === "de") {
            // setPromt(promt_de)
            setDefaultSysPromt(preparePrompt(props.botSettings?.defaultSystemPromptDe || defaultSysPrompt_de))
        } else {
            // setPromt(promt_en)
            setDefaultSysPromt(preparePrompt(props.botSettings?.defaultSystemPromptEn || defaultSysPrompt_en))
        }
        // eslint-disable-next-line
    }, [i18n.language, props.botSettings?.defaultSystemPromptDe, props.botSettings?.defaultSystemPromptEn]);
    
    React.useEffect(()=>{
        setNlpTask(props.nlpTask)
    }, [props.nlpTask])
    
    React.useEffect(()=>{
        if (props.botSettings?.nlpModel) {
            setNlpModel(props.botSettings.nlpModel)
        }
    }, [props.botSettings?.nlpModel])
    
    React.useEffect(()=>{
        if (props.nlpModel ) {
            setNlpModel(props.nlpModel )
        }
    }, [props.nlpModel])
    
    React.useEffect(()=>{
        if (props.botSettings?.temperature) {
            setTemperature(props.botSettings.temperature)    
        }
        // eslint-disable-next-line
    }, [props.botSettings?.temperature])
    
    React.useEffect(()=>{
        if (props.botSettings?.titleText) {
            setBotTitleText(preparePrompt(props.botSettings.titleText))    
        }
        // eslint-disable-next-line
    }, [props.botSettings?.titleText])
    
    React.useEffect(()=>{
        if (props.botSettings?.icon) {
            setBotIcon(props.botSettings.icon)    
        }
        // eslint-disable-next-line
    }, [props.botSettings?.icon])
    
    React.useEffect(()=>{
        if (props.botSettings?.colors) {
            setBotColors(props.botSettings.colors)    
        }
        // eslint-disable-next-line
    }, [props.botSettings?.colors])
    
    React.useEffect(()=>{
        if (props.botSettings?.chunksToFetch) {
            setFetchSimilarDocs(props.botSettings.chunksToFetch)    
        }
        // eslint-disable-next-line
    }, [props.botSettings?.chunksToFetch])
    
    React.useEffect(()=>{
        if (props.botSettings?.addSources) {
            setAddSources(props.botSettings.addSources)    
        }
        // eslint-disable-next-line
    }, [props.botSettings?.addSources])
    
    React.useEffect(()=>{
        if (props.botSettings?.checkAnswers) {
            setCheckAnswers(props.botSettings.checkAnswers)    
        }
        // eslint-disable-next-line
    }, [props.botSettings?.checkAnswers])
    
    React.useEffect(()=>{
        if (props.botSettings?.maxNewTokens) {
            setMaxNewTokens(props.botSettings.maxNewTokens)    
        }
        // eslint-disable-next-line
    }, [props.botSettings?.maxNewTokens])
    
    React.useEffect(()=>{
        setMessages(getWelcomeMessages(i18n.language))
        // eslint-disable-next-line
    }, [nlpTask])

    React.useEffect(() => {
        if (props.questionToAsk && props.questionToAsk.length > 0) {
            setUserResponse(props.questionToAsk)
            setQuestionFromOutside(props.questionToAsk)
        }
    }, [props.questionToAsk])

    React.useEffect(() => {
        if (questionFromOutside && questionFromOutside.length > 0) {
            handleSubmit(questionFromOutside, (awaitUserFeedback ? "Feedback-3-send" : ""))
        }
        // eslint-disable-next-line
    }, [questionFromOutside]);

    React.useEffect(() => {
        if (messages.length === 1) {
            speechHandler(messages[0].message, autoReadResults)
        }
    }, [messages, speechHandler, autoReadResults])

    React.useEffect(() => {
        console.log("autoReadResults changed to " + autoReadResults)
    }, [autoReadResults])

    const optionClick = (e: React.MouseEvent<HTMLElement>) => {
        let option = e.currentTarget.dataset.id;
        if (option) {
            // setNextStep(option);
        }
    };

    const getWelcomeMessages = (lang: string): ChatMessage[] => {
        let welcomeMsgs = ["Hi, I'm {{botName}}!"]
        if (props.botSettings) {
            if (nlpTask === "RAG" && props.botSettings.welcome_rag) {
                if (lang in props.botSettings.welcome_rag) {
                    welcomeMsgs = props.botSettings.welcome_rag[lang]
                } 
            } else if (nlpTask === "CIS" && props.botSettings.welcome_cis) {
                if (lang in props.botSettings.welcome_cis) {
                    welcomeMsgs = props.botSettings.welcome_cis[lang]
                }
            }
        }

        const initMsgs: ChatMessage[] = []
        welcomeMsgs.forEach((msg: string) => {
            initMsgs.push({
                purpose: "introduction",
                message: preparePrompt(msg),
                sender: "bot",
                timestamp: new Date().toLocaleString(),
                sources: new Array<PageContent>(),
                best_sources: "",
                cost: 0,
                query_about: "",
            })
        })
        
        return initMsgs;
    }
    
    // event handlers
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setUserResponse(e.target.value);
    };

    const handleTranscription = (newTranscription: string, final: boolean) => {
        if (final) {
            setUserResponse(newTranscription);
        } else {
            setUserResponse(prev => prev + newTranscription);
        }

        if (newTranscription.trim().toLowerCase().endsWith("abschicken")
            || newTranscription.trim().toLowerCase().endsWith("abschicken.")
            || newTranscription.trim().toLowerCase().endsWith("abschicken!")
        ) {
            setEndRecording(true)
            handleSubmit(newTranscription.replace("abschicken", "")
                .replace("Abschicken", ""), (awaitUserFeedback ? "Feedback-3-send" : ""))
        }
    };
    
    const onStartQuestionRecording = () => {
        setIsQuestionRecording(true)
    }
    const onStopQuestionRecording = () => {
        setIsQuestionRecording(false)
    }

    const addUserKnowledgeToBot = () => {
        chatbotClient.addUserKnowledgeToBot(props.botId)
    }
    
    const navTo = (targetNlpTask: string) : void => {
        // Parse the current query parameters
        const searchParams = new URLSearchParams(location.search);

        // Add or update a query parameter, e.g., set `exampleParam` to `newValue`
        searchParams.set('nlpTask', targetNlpTask);

        // Construct the new URL
        const newUrl = `${location.pathname}?${searchParams.toString()}`;

        // Navigate to the new URL
        navigate(newUrl);
    }
    
    const hexToRgb = (hex: string): string => {
        hex = hex.replace(/^#/, '');

        if (hex.length === 3) {
            hex = hex.split('').map((char) => char + char).join('');
        }

        // Überprüft, ob ein Alpha-Wert im Hex-String vorhanden ist (8-stellig)
        if (hex.length === 8) {
            hex = hex.slice(0, 6);
        }

        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        return `${r}, ${g}, ${b}`;
    }
    
    const handleChatbotLogin = () => {
        // check session storage, if username/password already in place
        const username = sessionStorage.getItem('/' + props.botId + '/username');
        const password = sessionStorage.getItem('/' + props.botId + '/password');

        if (username && password) {
            setBotUsername(username)
            setBotPassword(password)
            setLoggedIn(true)
        }
    }
    
    React.useEffect(()=>{
        handleChatbotLogin()
        // eslint-disable-next-line
    }, [])

    const handleSubmit = (theQuestion: string, extraParam?: string) => {
        setPageError(undefined)
        setStreamAnswer("")

        if (extraParam === "Feedback-3-send") {
            theQuestion = userResponse
        }

        let userConfirmedPersonalDataProcessing = false
        if (extraParam === "agree_sending_personal_data") {
            userConfirmedPersonalDataProcessing = true
        }                

        if (theQuestion.length === 0) {
            return
        }
        
        if (props.sendEventFromIFrameToParent) {
            props.sendEventFromIFrameToParent("newQuestionSubmitted", theQuestion)
        }

        setMessages(messages => [...messages, {
            purpose: "",
            message: (selctedTopic.length > 0 ? selctedTopic + ": " : "") + theQuestion,
            sender: "user",
            timestamp: new Date().toLocaleString(),
            sources: new Array<PageContent>(),
            best_sources: "",
            cost: 0,
            query_about: ""
        }])

        if (extraParam === "dont_send_personal_data") {
            setUserResponse("");
            const res = {
                purpose: "res",
                message: preparePrompt(
                    "In Ordnung."),
                sources: [],
                best_sources: "",
                cost: 0,
                query_about: "",
            }
            speechHandler(res.message, autoReadResults)
            setStreamAnswer("")
            setMessages(messages => [...messages, { ...res,
                sender: "bot",
                timestamp: new Date().toLocaleString()
            }])
            setShowWaitForResponse(false)
            return
        }
        
        if (extraParam === "Feedback-1") {
            setUserResponse("");
            const res = {
                purpose: "feedback",
                message: preparePrompt(
                    "Sicherlich. Das Team freut sich über Lob, konstruktive Kritik und Verbessungsvorschlage. " +
                    "Schreibe dein Anliegen gerne unten ein und drücke anschließend auf 'Feedback senden'. " +
                    "Anschließend wird deine Eingabe und der komplette Chat-Verlauf an das KI-Team geschickt. " +
                    "Wenn du das nicht möchtest, kannst du das Chat-Fenster jetzt schließen und keine Daten werden gesendet."),
                sources: [],
                best_sources: "",
                cost: 0,
                query_about: "Feedback-2",
            }
            setAwaitUserFeedback(true)
            speechHandler(res.message, autoReadResults)
            setStreamAnswer("")
            setMessages(messages => [...messages, { ...res,
                sender: "bot",
                timestamp: new Date().toLocaleString()
            }])
            setShowWaitForResponse(false)
            
            return
        } else if (extraParam === "Feedback-3-send") {
            setAwaitUserFeedback(false)
            setUserResponse("");
            const res = {
                purpose: "feedback",
                message: preparePrompt(
                    "Vielen Dank, wir haben das Feedback an das Team gesendet."),
                sources: [],
                best_sources: "",
                cost: 0,
                query_about: "Feedback-4",
            }
            speechHandler(res.message, autoReadResults)
            setStreamAnswer("")
            setMessages(messages => [...messages, { ...res,
                sender: "bot",
                timestamp: new Date().toLocaleString()
            }])
            setShowWaitForResponse(false)
            
            const pdfstring = base64_pdf
            // const feedback = messages[messages.length - 2].message
            const feedback = userResponse

            const dateTime = new Date(); // current date and time
            const dateTimeISO = dateTime.toISOString(); // "2024-08-22T13:47:23.000Z"
            
            const feedbackContent: FeedbackContent = {
                createdAt: dateTimeISO,
                feedback: feedback,
                chatPdfAsBase64: pdfstring
            }

            chatbotClient.giveFeedback(props.botId, feedbackContent)
            
            return
        }

        const fillerMessages = [
            "Gerne beantworte ich Ihre Frage, einen Moment bitte.",
            "Einen Moment bitte, ich schau schnell nach.",
            "Ich kümmere mich sofort darum, einen Moment bitte.",
            "Danke für Ihre Geduld, ich sehe sofort nach.",
            "Einen Moment, ich hole die nötigen Informationen für Sie.",
            "Einen Augenblick, ich recherchiere das für Sie.",
            "Ich bin gleich für Sie da, einen kurzen Moment bitte.",
            "Lassen Sie mich kurz nachsehen, einen Moment bitte.",
            "Ich schaue sofort nach, einen kleinen Augenblick bitte.",
            "Ihre Frage wird gleich beantwortet, einen Moment bitte.",
            "Ich bin gleich für Sie zurück mit der Antwort, einen Augenblick bitte."
        ]
        setTextToSay(fillerMessages[Math.floor(Math.random() * fillerMessages.length)])

        setTimeout(() => {
            setShowWaitForResponse(true)
        }, 200);

        

        const chatRequest = {
            question: theQuestion + " " + (easyLangEnabled ? " Antworte in leichter Sprache." : ""), // + (i18n.language === "de" ? "Antworte ausschließlich auf Deutsch." : "Answer in english only."), // "Can I use the ULTRIS camera while being on an airplane?",
            userConfirmedPersonalDataProcessing: userConfirmedPersonalDataProcessing,
            answerLanguage: getAnswerLanguage(i18n.language),
            history: chatHistoryFromServer,
            chat_type: nlpTask,
            extra_param: (extraParam ? extraParam : ""),
            personality_prompt: selectedAvatar.prompt,
            username: botUsername,
            password: botPassword,
        }

        chatbotClient.chatWithChatbot(props.botId, chatRequest,
            (chunk: string, event_type: string) => {
                if (event_type === "answer_chunk") {
                    setStreamAnswer(prevStreamAnswer => prevStreamAnswer + chunk)    
                }
            },
            (lastChunkReceived: string) => {
                // console.log(lastChunkReceived)
                const fullAnswerObj = lastChunkReceived     
                
                let res = {
                    purpose: "res",
                    message: "",
                    sources: [{
                        page_content: "",
                        metadata: {
                            source: "",
                            page: 0,
                            topic: "",
                            similarity_score: 0,
                            store: "",
                        }
                    }],
                    best_sources: "",
                    cost: 0,
                    query_about: ""
                }
                if (lastChunkReceived.includes("Personal data detected. Are you sure you want to proceed?")) {
                    res["message"] = "⚠ Es scheint, als ob du eine Anfrage mit personenbezogene Daten absetzen möchtest.\n" +
                        "Bist du sicher?"
                    res["query_about"] = "ask_sending_personal_data " + chatRequest.question
                } else if (lastChunkReceived.includes("Personal data detected, I cannot answer that question, sorry.")) {
                    res["message"] = "🚫 Entschuldigung, Anfragen mit personenbezogenen Daten können nicht beantwortet werden."
                    res["query_about"] = ""
                } else if (lastChunkReceived.includes("Access denied")) {
                    res["message"] = "🚫 " + lastChunkReceived
                    res["query_about"] = ""
                } else {
                    const response = JSON.parse(fullAnswerObj) as ChatResponse;
                    setChatHistoryFromServer(response.history)
                    setBase64_pdf(response.history_as_pdf)
                    if (response.source_documents !== undefined) {
                        response.source_documents.forEach(pageContent => {
                            pageContent.metadata.source = cleanLinks(pageContent.metadata.source)
                        })
                    }
                    res = {
                        purpose: "res",
                        message: preparePrompt(response.result),
                        sources: response.source_documents,
                        best_sources: response.best_sources,
                        cost: response.token_usage.costs,
                        query_about: response.query_about,
                    }
                    setTextToSay(preparePrompt(response.result).replace(/<[^>]*>/g, ""))

                    const videoSources = response.source_documents.filter((source) => source.metadata.store === "video")

                    videoSources.forEach(video => {
                        console.log("video")
                        console.log(props.postVideoToIframe)
                        props.postVideoToIframe && props.postVideoToIframe({
                            title: video.metadata.title ?? 'Unknown title',
                            url: video.metadata.link ?? '',
                            jwt: video.metadata.jwt??'',
                            searchMatches: video.metadata.search_matches ?? [],
                            thumbnail: video.metadata.thumbnail ?? ''
                        })
                    });
                    
                }
               
                speechHandler(res.message, autoReadResults)
                setStreamAnswer("")
                setMessages(messages => [...messages, { ...res,
                    sender: "bot",
                    timestamp: new Date().toLocaleString()
                }])
                if (props.sendEventFromIFrameToParent) {
                    props.sendEventFromIFrameToParent("newAnswerReady", res["message"])
                }
                setShowWaitForResponse(false)
            },
            (selectedAdditionalFileToChatWith ? selectedAdditionalFileToChatWith : undefined)       
        )
        /*
        chatbotClient.chatWithChatbot(props.botId, chatRequest)
            .then((response: ChatResponse) => {
                setChatHistoryFromServer(response.history)
                setBase64_pdf(response.history_as_pdf)
                response.source_documents.forEach(pageContent => {
                    pageContent.metadata.source = cleanLinks(pageContent.metadata.source)
                })
                const res = {
                    purpose: "res",
                    message: response.result,
                    sources: response.source_documents,
                    best_sources: response.best_sources,
                }
                speechHandler(res.message, autoReadResults)
                setMessages(messages => [...messages, { ...res, 
                    sender: "bot", 
                    timestamp: new Date().toLocaleString() 
                }])
                

            })
            .catch((e: ErrorResponse) => { setPageError(e.message) })
            .finally(() => { setShowWaitForResponse(false) })
        */
        setUserResponse("");
         

    };
    
    const avatarQualityType = (qual: string) => {
        if (qual === "Low") {
            return AvatarQuality.Low
        } else if (qual === "Medium") {
            return AvatarQuality.Medium
        } else {
            return AvatarQuality.High
        }
    }

    return (
        <>
            <Box sx={(props.undecorated ? 
                    ({
                        ...ChatContainerStylesUndecorated,
                        height: (props.botSettings.hideChatIconInIFrame ? "100vh" : "85vh"),
                        backgroundColor: "rgba(" + (botColors.botbackground || "#ffffff") + ", " + botOpacity + ")",
                        marginLeft: "8px",
                        marginRight: "3px",
                    }) : 
                    ({
                        ...ChatContainerStyles,
                        border: `0.5px solid ${botColors.primary}`,
                        backgroundColor: "rgba(" + (botColors.botbackground || "#ffffff") + ", " + botOpacity + ")"
                    })
                )} 
                 onKeyDownCapture={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        handleSubmit(userResponse, (awaitUserFeedback ? "Feedback-3-send" : ""))
                    }
                }}
            >
                {/*Header bar */}
                <Grid container justifyContent={"space-between"} alignItems={"center"} 
                    sx={{ 
                        backgroundColor: botColors.primary,
                        border: `0.5px solid ${botColors.primary}`,
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        padding: "8px",
                        color: (botColors.primaryText || botColors.light)
                    }}
                >
                    <Grid item>
                        <Grid container alignItems={"center"} spacing={3}>
                            <Grid item>
                                <IconButton onClick={handleAvatarClick}>
                                    <Avatar alt={"bot"} src={botIcon} />                                    
                                </IconButton>
                                {props.botSettings && props.botSettings.hasChatCharactersEnabled && 
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={handleAvatarClose}
                                    >
                                        {avatarOptions.map((option) => (
                                            <MenuItem key={option.prompt} onClick={() => handleAvatarMenuItemClick(option)}>
                                                <Avatar style={{ marginRight: 8 }}>
                                                    {option.icon}
                                                </Avatar>
                                                <ListItemText
                                                    primary={option.name}
                                                    secondary={option.descr}
                                                />
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                }
                            </Grid>
                            <Grid item>
                                {botTitleText}
                            </Grid>
                        </Grid>
                    </Grid>
                    { props.botSettings && props.botSettings.hasInfoPageEnabled && 
                        <Grid item>
                            <PulsatingButton color="inherit" onClick={() => setInfoScreen(!infoScreen)} 
                                             sx={{width: "24px", height: "24px", padding: 0, minWidth: 0, marginRight: "5px"}}>
                                {infoScreen ? 
                                    <IoCloseSharp size={24} /> :
                                    <IoIosInformationCircleOutline size={24} />
                                }
                            </PulsatingButton>
                        { props.botSettings && props.onClose && !infoScreen && 
                            <Button color="inherit" onClick={props.onClose} sx={{width: "24px", height: "24px", padding: 0, minWidth: 0}}>
                                <IoCloseSharp size={24} />
                            </Button>
                        }
                        </Grid>
                    }
                </Grid>
                
                <ChatSettingsModal
                    open={settingsModalOpen}
                    onClose={() => {
                        setSettingsModalOpen(false)
                    }}
                    embeddings={embeddings}
                    setEmbeddings={setEmbeddings}
                    nlpModel={nlpModel} setNlpModel={setNlpModel}
                    nlpTask={nlpTask} setNlpTask={setNlpTask}
                    maxLen={maxLen} setMaxLen={setMaxLen}
                    maxNewTokens={maxNewTokens} setMaxNewTokens={setMaxNewTokens}
                    temperature={temperature} setTemperature={setTemperature}
                    topP={topP} setTopP={setTopP}
                    defaultSysPromt={defaultSysPromt} setDefaultSysPromt={setDefaultSysPromt}
                    fetchSimilarDocs={fetchSimilarDocs} setFetchSimilarDocs={setFetchSimilarDocs}
                    similarityScoreThreshold={similarityScoreThreshold} setSimilarityScoreThreshold={setSimilarityScoreThreshold}
                    speechUtterance={utterThis}
                    autoReadResults={autoReadResults} setAutoReadResults={setAutoReadResults}
                    checkAnswers={checkAnswers} setCheckAnswers={setCheckAnswers}
                    addSources={addSources} setAddSources={setAddSources}
                />
                {pageError &&
                    <Grid container justifyContent='center'>
                        <Alert severity="error">
                            {pageError}
                        </Alert>
                    </Grid>
                }

                {loginRequired && !loggedIn &&
                    <Box
                        sx={{
                            position: "relative",
                            backgroundColor: "rgba(" + hexToRgb(botColors.botbackground || "#ffffff") + ", " + botOpacity + ")",
                            overflow: "hidden", padding: 0, height: "100%", // 2/3 height
                        }}
                    >
                        <LoginModal botId={props.botId} isOpen={!loggedIn} onClose={handleChatbotLogin} mainColor={props.botId}/>
                    </Box>
                }
                
                {infoScreen &&
                    <Box
                        sx={{
                            overflowY: 'auto',
                            backgroundColor: "rgba(" + hexToRgb(botColors.botbackground || "#ffffff") + ", " + botOpacity + ")"
                        }}
                    >
                        <Grid container direction="column" sx={{
                            width: '100%', 
                            height: "100%", 
                            padding: "1.5em",
                            justifyContent: 'space-between',
                        }}>
                            <Grid item xs={11}>
                                <Typography fontSize={"10px"}>
                                    {"Dieser Chatbot wurde erstellt durch "}
                                    <Link href="https://youniquehorns.com/" variant="inherit" target="_blank">
                                        YOUniquehorns GmbH
                                    </Link>
                                </Typography><br/>
                                <Typography>
                                    <HtmlRenderer htmlString={(
                                        props.botSettings && props.botSettings.descriptions ? props.botSettings.descriptions['de'][0]
                                            .replaceAll("{{botName}}", props.botName) 
                                            .replaceAll("{{linkToDataProtectionDeclaration}}", props.botSettings.linkToDataProtectionStatement) 
                                            .replaceAll("{{feedbackEmail}}", props.botSettings.feedbackEmailAddress) 
                                            : ""
                                    )} />
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                }
                {!infoScreen && 
                    <>
                        {(props.botSettings?.avatarEnabled) ?
                            <>
                                {/* InteractiveAvatar2 takes 2/3 of the space */}
                                <Box
                                    sx={{
                                    position: "relative", 
                                    backgroundColor: "rgba(" + hexToRgb(botColors.botbackground || "#ffffff") + ", " + botOpacity + ")",
                                    overflow: "hidden", padding: 0, height: "66.66%", // 2/3 height
                                }}
                                >
                                    {/* Fade-out effect overlay */}
                                    <Box
                                        sx={{
                                            position: "absolute",
                                            bottom: 0, left: 0, right: 0,
                                            height: "40px", // Adjust height as needed for fade effect
                                            background: `linear-gradient(to bottom, rgba(` + hexToRgb(botColors.botbackground || "#ffffff") + `, 0) 0%, rgba(` + hexToRgb(botColors.botbackground || "#ffffff") + `, 1) 30px, rgba(` + hexToRgb(botColors.botbackground || "#ffffff") + `, 1) 100%)`,
                                            pointerEvents: "none", // Prevent the overlay from blocking scroll events
                                            zIndex: 5,
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            position: "absolute",
                                            top: 0, left: 0, right: 0,
                                            height: "40px", // Adjust height as needed for fade effect
                                            background: `linear-gradient(to top, rgba(` + hexToRgb(botColors.botbackground || "#ffffff") + `, 0) 0%, rgba(` + hexToRgb(botColors.botbackground || "#ffffff") + `, 1) 30px, rgba(` + hexToRgb(botColors.botbackground || "#ffffff") + `, 1) 100%)`,
                                            pointerEvents: "none", // Prevent the overlay from blocking scroll events
                                            zIndex: 5,
                                        }}
                                    />
                                    <InteractiveAvatar
                                        userInteracted={userGaveConsent}
                                        botId={props.botId}
                                        avatarName={props.botSettings!.avatarId || ""}
                                        avatarIdCustom={props.botSettings!.avatarIDCustom || undefined}
                                        language={i18n.language}
                                        textToSay={textToSay}
                                        quality={avatarQualityType(props.botSettings!.avatarQuality || "Low")}
                                        buttonColor={(botColors.contrast || botColors.primary)}
                                        backgroundImageUrl={props.botSettings.avatarBackgroundUrl || undefined}
                                        interruptSpeaking={isQuestionRecording}
                                    />
                                </Box>
                                
                                <Box
                                    sx={{
                                        overflowY: "hidden",
                                        backgroundColor: (botColors.botbackground || "#ffffff"),
                                        padding: ".5em",
                                        height: "33.33%", // 1/3 height
                                        position: 'relative', // Position relative to enable absolute positioning of overlay
                                    }}
                                >
                                    {/* Fade-out effect overlay */}
                                    <Box
                                        sx={{
                                            position: "absolute",
                                            top: 0, left: 0, right: 0,
                                            height: "40px", // Adjust height as needed for fade effect
                                            background: `linear-gradient(to top, rgba(${hexToRgb(botColors.botbackground || "#ffffff")}, 0) 0%, rgba(${hexToRgb(botColors.botbackground || "#ffffff")}, 1) 30px, rgba(${hexToRgb(botColors.botbackground || "#ffffff")}, 1) 100%)`,
                                            pointerEvents: "none", // Prevent the overlay from blocking scroll events
                                            zIndex: 5,
                                        }}
                                    />
                                    
                                    <Chats
                                        showWaitForResponse={showWaitForResponse}
                                        optionClick={optionClick}
                                        messages={messages}
                                        numberOfWelcomeMsgs={getWelcomeMessages(i18n.language).length}
                                        streamAnswer={streamAnswer}
                                        undecorated={props.undecorated}
                                        speechUtterance={utterThis}
                                        botName={props.botName}
                                        nlpTask={nlpTask}
                                        chatWithBot={handleSubmit}
                                        botColors={botColors}
                                        botSettings={props.botSettings!}
                                        opacity={undefined}
                                    />
                                    
                                </Box>
                            </> :
                            <Box
                                sx={{
                                    position: "relative", 
                                    backgroundColor: "rgba(" + hexToRgb(botColors.botbackground || "#ffffff") + ", " + botOpacity + ")",
                                    overflow: "hidden", padding: 0, height: "100%", // 2/3 height
                                }}
                            >
                                <Chats
                                    showWaitForResponse={showWaitForResponse}
                                    optionClick={optionClick}
                                    messages={messages}
                                    numberOfWelcomeMsgs={getWelcomeMessages(i18n.language).length}
                                    streamAnswer={streamAnswer}
                                    undecorated={props.undecorated}
                                    speechUtterance={utterThis}
                                    botName={props.botName}
                                    nlpTask={nlpTask}
                                    chatWithBot={handleSubmit}
                                    botColors={botColors}
                                    botSettings={props.botSettings!}
                                    opacity={undefined}
                                />

                                {/* List of files the user attached to this bot */}
                                <Box style={{ position: "absolute", top: 70, right: -32, zIndex: 3 }}>
                                    <FileIconsList 
                                        bot_id={props.botId} 
                                        onEntryEnabledChange={(enabledEntries: string[]) => {
                                            if (enabledEntries.length > 0) {
                                                const storedData  = localStorage.getItem(enabledEntries[0]);
                                                if (storedData) {
                                                    const fileName = enabledEntries[0].replaceAll("/faissUsrUploads/" + props.botId + "/", "")
                                                    const fileType = "application/octet-stream"
                                                    // Parse the stored JSON string back to a Uint8Array
                                                    const byteArray = new Uint8Array(JSON.parse(storedData));
                                                    const blob = new Blob([byteArray], { type: fileType });
                                                    // Recreate the File object
                                                    const restoredFile = new File([blob], fileName, { type: fileType });
                                                    setSelectedAdditionalFileToChatWith(restoredFile);
                                                } else {
                                                    console.error("No file found in localStorage");
                                                }
                                            }
                                        }}
                                        contrastColor={botColors.contrast}
                                    />
                                </Box>
                            </Box>
                        }
                </>}

                {filterVisible && false &&
                    <Grid container direction="column" sx={{
                        width: '100%',
                        height: "100%",
                        background: 'white',
                        padding: "1.5em",
                        justifyContent: 'space-between',
                        backgroundColor: "rgba(" + (botColors.botbackground || "#ffffff") +", " + botOpacity + ")",
                        overflowY: 'auto',
                    }}>
                        <Grid item xs={11}>
                            <Typography fontSize={"10px"}>
                                {"Filter "}
                                <Link href="https://youniquehorns.com/" variant="inherit" target="_blank">
                                    YOUniquehorns GmbH
                                </Link>
                            </Typography><br/>
                            <Typography>
                                <HierarchyTree checkboxColor={botColors.contrast}/>
                            </Typography>
                        </Grid>
                    </Grid>
                }

                <Box sx={{backgroundColor: "rgba(" + hexToRgb(botColors.botbackground || "#ffffff") + ", 0)",}}>
                    
                    {/* Topics */}
                    {props.botTopics && props.botTopics.length > 0 && 
                        <ListItem sx={{ paddingLeft: "55px", paddingRight: "0px", color: "gray", fontSize: '12px', backgroundColor: "rgba(" + hexToRgb(botColors.botbackground || "#ffffff") + ", " + botOpacity + ")" }}
                        >
                            {t("chat.topic")}
                            <IconButton size={"small"} sx={{margin: "0px", padding: "0 0 7px 0", color: theme.palette.primary.main}}
                                onClick={()=>setQuestionToAsk(t("chat.help.whatAreTopicsGoodFor"))}
                            >
                                <HelpOutlineOutlinedIcon sx={{width: "12px"}}/>
                            </IconButton> :
                            {props.botTopics.map(topic => {
                                const isSelected = (topic === selctedTopic)    
                                return (<>
                                <Chip label={topic} size="small" 
                                      sx={{margin: "0 3px 0 3px"}} variant={(isSelected ? "filled" : "outlined")}
                                      onClick={()=>{
                                          if (isSelected) {
                                              setSelctedTopic("")
                                          } else {
                                              setSelctedTopic(topic)    
                                          }
                                      }}
                                />
                                </>)
                            })}
                        </ListItem>
                    }
                    
                    {/* Default Questions */}
                    {userGaveConsent && props.botSettings.hasDefaultQuestionsEnabled && 
                        Object.entries(props.botSettings.defaultQuestions).length > 0 &&
                        <ListItem sx={{  paddingRight: "0px", color: botColors.primary, fontSize: '12px', 
                            backgroundColor: "rgba(" + hexToRgb(botColors.botbackground || "#ffffff") + ", " + botOpacity + ")" }}
                        >
                            {Object
                                .entries(props.botSettings.defaultQuestions)
                                .map(([key, value]) => {
                                    return (<Tooltip title={value} arrow>
                                        <Chip label={key} size="small"
                                              sx={{margin: "0 3px 0 3px", backgroundColor: botColors.botbackground, 
                                                  color: botColors.contrast, borderColor: botColors.contrast
                                                }} variant={"outlined"}
                                              onClick={()=>handleSubmit(value)}
                                        />
                                    </Tooltip>)
                            })}
                        </ListItem>
                    }

                    {/* Filter Button */}
                    { false && 
                        <ListItem sx={{  paddingRight: "0px", color: botColors.primary, fontSize: '12px',
                            backgroundColor: "rgba(" + hexToRgb(botColors.botbackground || "#ffffff") + ", " + botOpacity + ")" }}
                        >
                            <Button color="inherit" onClick={() => setFilterVisible(!filterVisible)} sx={{width: "24px", height: "24px", padding: 0, minWidth: 0, marginRight: "5px"}}>
                                <CiFilter size={24} />
                            </Button>
                        </ListItem>
                    }
                    
                    {/* Don't share personal data info */}
                    <Box
                        sx={{
                            fontSize: "8px", textAlign: "center", color: "gray", paddingTop: "0px",
                            marginLeft: "auto", marginRight: "auto", display: "flex",
                            justifyContent: "center", alignItems: "center", border: "none", boxShadow: "none", 
                            backgroundColor: "rgba(" + hexToRgb(botColors.botbackground || "#ffffff") + ", " + botOpacity + ")"
                        }}
                    >
                        {t("chat.dontSharePersonalData")}
                    </Box>
                    
                    {/* Input & Settings */}
                    <ListItem alignItems="flex-start" sx={{ padding: "0px"
                    }}>
                        <Paper
                            component="form"
                            sx={{ 
                                p: '8px 8px', 
                                display: 'flex', 
                                alignItems: 'center', 
                                width: "100%", 
                                borderBottomLeftRadius: "10px",
                                backgroundColor: "rgba(" + hexToRgb(botColors.botbackground || "#ffffff") + ", " + botOpacity + ")", 
                                borderBottomRightRadius: "10px", 
                                borderTopRightRadius: "0px", 
                                borderTopLeftRadius: "0px", 
                                borderTop: `1px solid ${(botColors.contrast || botColors.primary)}` 
                            }}
                        >
                            <LanguageSwitcher
                                easyLangEnabled={easyLangEnabled}
                                setEasyLangEnabled={setEasyLangEnabled}
                            />
                            {!userGaveConsent ? (
                                <Chip
                                    label={
                                        <Box sx={{ display: 'flex', alignItems: 'center', color: (botColors.contrast || botColors.primary)}}>
                                                <span
                                                    onClick={() => {
                                                        if (props.botSettings.linkToDataProtectionStatement.length > 0) {
                                                            window.open(props.botSettings.linkToDataProtectionStatement, '_blank');
                                                        } else {
                                                            setInfoScreen(!infoScreen)
                                                        }
                                                    }} 
                                                    style={{
                                                        cursor: 'pointer',
                                                        textDecoration: 'underline',
                                                        color: '#3f51b5', // Optional: specify the color directly
                                                    }}
                                                >
                                                    Datenverarbeitung
                                                </span>&nbsp;{' ok?'}
                                            <IconButton size="small" onClick={handleDeleteConsent} sx={{color: (botColors.contrast || botColors.primary)}}>
                                                <CheckIcon />
                                            </IconButton>
                                            <IconButton size="small" onClick={() => {}} sx={{color: (botColors.contrast || botColors.primary)}}>
                                                <CloseIcon />
                                            </IconButton>

                                        </Box>
                                    }
                                    sx={{ ml: 1 }}
                                />
                            ) : (!awaitUserFeedback 
                                && messages.length > 16 
                                && (props.botId === 'a2ed0962-0cf6-44f4-9f1c-46f596dee46b' || props.botId === 'fade0059-bae3-4d58-82b8-4aaa4621a644') ?
                                (
                                    <Chip
                                        label={
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                Zu viele Anfragen, bitte neu starten.
                                            </Box>
                                        }
                                        sx={{ ml: 1 }}
                                    />
                                )
                                : (
                                    <>
                                        {/*<Dictaphone1 />*/}
                                        {props.botSettings && props.botSettings.hasSpeechInputEnabled && 
                                            <Dictaphone2 
                                                onTranscription={handleTranscription} 
                                                endRecording={endRecording}
                                                iconColor={(botColors.contrast || botColors.primary)} 
                                                iconColorRecording={botColors.botText}
                                                startRecordingCallback={onStartQuestionRecording}
                                                stopRecordingCallback={onStopQuestionRecording}
                                            />
                                        }

                                        {/* User Input */}
                                        <InputBase
                                            sx={{ ml: 1, flex: 1, color: (botColors.contrast || botColors.primary) }}
                                            placeholder={t("chat.userInput")}
                                            inputProps={{ 'aria-label': 'user input' }}
                                            onChange={e => handleInputChange(e)}
                                            value={userResponse}
                                        />
                                        
                                        {/* Send Button */}
                                        <IconButton sx={{color: (botColors.contrast || botColors.primary)}} onClick={
                                            () => handleSubmit(userResponse, (awaitUserFeedback ? "Feedback-3-send" : ""))
                                        }>
                                            { selectedAvatar.name !== "" ? selectedAvatar.icon : <IoIosSend />}
                                        </IconButton>
                                    </>
                                )
                            )}
                            

                            {/* Halucination warning */}
                            {props.botSettings.showAdditionalHallucinationWarning
                                && showBubble 
                                && userGaveConsent && 
                                <Box sx={{ position: 'relative', display: 'inline-block', marginTop: '20px' }}>
                                    <SpeechBubble
                                        text="Ich weiß viel, kann mich aber leider auch irren. <br/>Prüfe wichtige Informationen!"
                                        onClose={handleCloseBubble}
                                        position="bottom-right" // Change position as needed
                                    />
                                </Box>
                            }

                            {/* Settings button */}
                            {!props.undecorated && false &&
                                <>
                                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                    
                                    {(userCtx.isLoggedIn()) &&
                                        <IconButton onClick={() => setSettingsModalOpen(true)}>
                                            <IoSettingsOutline />
                                        </IconButton>
                                    }  
                                </>
                            }
                            
                            {/* switch between nlp-tasks */}
                            {props.botId !== 'a2ed0962-0cf6-44f4-9f1c-46f596dee46b'
                                && props.botId !== 'fade0059-bae3-4d58-82b8-4aaa4621a644'    
                                && props.botId !== '8350924f-c329-4573-95d3-de6bc6ca843a'   
                                && props.botSettings.hasCISpatternEnabled
                                && nlpTask.startsWith('RAG') && 
                                <>
                                    <Tooltip title={"Teach the bot something!"}>
                                        <IconButton onClick={() => navTo("CIS")}>
                                            <PiGraduationCapLight />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            }
                            {props.botSettings && props.botSettings.hasCISpatternEnabled
                                && nlpTask.startsWith('CIS') &&
                                <>
                                    <Tooltip title={"Upload something"}>
                                        <IconButton onClick={() => setAddFileModalOpen(true)}>
                                            <RiChatUploadLine />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={"Ask the bot something!"}>
                                        <IconButton onClick={() => navTo("RAG")}>
                                            <TbMessageChatbot  />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            }

                            {props.botSettings && userGaveConsent
                                && props.botSettings!.hasChatWithFilesEnabled
                                && nlpTask.startsWith('RAG') &&
                                <>
                                    <Tooltip title={"Attach file to chat with"}>
                                        <IconButton onClick={() => setAttachFileModalOpen(true)}>
                                            <MdOutlineAttachFile />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            }
                            
                            {/* pdf download */}
                            {base64_pdf && base64_pdf.length > 0 
                                && props.botSettings!.hasDownloadChatAsPDFenabled &&
                                <>
                                    <a download={"chat_" + new Date().toISOString() + ".pdf"} 
                                       href={"data:application/pdf;base64," + base64_pdf} 
                                    >
                                        <IconButton >
                                            <RiChatDownloadLine />
                                        </IconButton>
                                    </a>
                                </>
                            }
                        </Paper>
                    </ListItem>

                    {/* Upload dialog for "Teach me something!" */}
                    <UploadFileModal
                        open={addFileModalOpen}
                        subfolder={'userInput'}
                        onClose={() => {
                            setAddFileModalOpen(false)
                            addUserKnowledgeToBot()
                        }}
                        bot_id={props.botId} 
                    />
                    <UploadFileModal2 
                        open={attachFileModalOpen}
                        onClose={() => {
                            setAttachFileModalOpen(false)
                        }}
                        bot_id={props.botId} 
                    />
                    
                </Box>
            </Box>
        </>
    );
};

export default Chat;
